<template>
  <!--Modal Calculator-->
  <div
    id="box-draggable"
    :class="{ active: this.$store.state.auth.toggleCalculator }"
  >
    <div class="modal-calculator">
      <div class="calculator">
        <div
          class="close"
          @click="
            this.$store.state.auth.toggleCalculator =
              !this.$store.state.auth.toggleCalculator
          "
        >
          <i class="d-inline-flex mdi mdi-close"></i>
        </div>
        <div class="header">
          <div class="old-computed" v-if="logList + current">
            {{ logList + current }}
          </div>
          <div class="old-computed" v-else>0</div>
          <div class="result-computed" v-if="answer">
            {{ answer ? "=" : "" }}
            {{ answer }}
          </div>
          <div class="result-computed" v-else>0</div>
        </div>
        <div class="body">
          <div class="row">
            <button class="light-dark" @click="clear">Ac</button>
            <button class="dark" @click="append('7')">۷</button>
            <button class="dark" @click="append('4')">۴</button>
            <button class="dark" @click="append('1')">۱</button>
            <button class="dark" @click="append('0')">۰</button>
          </div>
          <div class="row">
            <button class="light-dark" @click="clearOneNumber">C</button>
            <button class="dark" @click="append('8')">۸</button>
            <button class="dark" @click="append('5')">۵</button>
            <button class="dark" @click="append('2')">۲</button>
            <button class="dark" @click="append('000')">۰۰۰</button>
          </div>
          <div class="row">
            <button class="blue" @click="divide">/</button>
            <button class="dark" @click="append('9')">۹</button>
            <button class="dark" @click="append('6')">۶</button>
            <button class="dark" @click="append('3')">۳</button>
            <button class="dark" @click="dot">.</button>
          </div>
          <div class="row flex-grow">
            <button class="blue" @click="multiplied">×</button>
            <button class="blue" @click="minus">-</button>
            <button class="blue" @click="plus">+</button>
            <button class="green" @click="equal">=</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End Modal Calculator-->
</template>

<script>
export default {
  name: "modalCalculator",
  data() {
    return {
      logList: "",
      current: "",
      answer: "",
      operatorClicked: true,
    };
  },
  props: {
    statusCalculator: {
      type: Boolean,
      require: true,
    },
  },
  methods: {
    dragElement(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + "header").onmousedown =
          dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    },
    addtoLog(operator) {
      if (this.operatorClicked === false) {
        this.logList += `${this.current} ${operator} `;
        this.current = "";
        this.operatorClicked = true;
      }
    },
    append(number) {
      if (this.operatorClicked) {
        this.current = "";
        this.operatorClicked = false;
      }
      this.current = `${this.current}${number}`;
    },
    clear() {
      this.current = "";
      this.answer = "";
      this.logList = "";
      this.operatorClicked = false;
    },
    plus() {
      this.addtoLog("+");
    },
    divide() {
      this.addtoLog("/");
    },
    multiplied() {
      this.addtoLog("*");
    },
    minus() {
      this.addtoLog("-");
    },
    percent() {
      if (this.current !== "") {
        this.current = `${parseFloat(this.current) / 100}`;
      }
    },
    dot() {
      if (this.current.indexOf(".") === -1) {
        this.append(".");
      }
    },
    clearOneNumber() {
      if (this.current) {
        let array = this.current.split("");
        array.pop();
        const arrayToString = array.join("");
        this.current = arrayToString;
      }
    },
    equal() {
      if (this.operatorClicked === false) {
        const str = this.logList + this.current;
        let newStr = str.replace(/ 000 /gm, 0);
        this.answer = eval(newStr);
      } else {
        this.answer = "WHAT?!!";
      }
    },
  },

  mounted() {
    this.dragElement(document.getElementById("box-draggable"));
  },
};
</script>

<style scoped></style>
